// Foundation JavaScript
// Documentation can be found at: http://foundation.zurb.com/docs
$(document).foundation();

// Foundation Accordian Menu
$(document).foundation({
  accordion: {
    content_class: 'content',
    active_class: 'open',
    multi_expand: false,
    toggleable: true
  }
});

$(function () {
  $(".accordion").on("click", "dd", function (event) {
    if (!($(this).hasClass("open"))) {
      $("dd.open").removeClass('open').find(".content").slideUp("fast");
    }
    $(this).toggleClass('open').find(".content").slideToggle("fast");
  });
});

// Sequence sliders
$(function () {
  var bannerExists = document.getElementById("banner");
  if (bannerExists !== null) {
    var sequenceBanner = document.getElementById("banner");
    var optionsBanner = {
      animateCanvas: false,
      startingStepAnimatesIn: false,
      autoPlay: true,
      autoPlayInterval: 2000,
      preloader: true
    };
    var bggSequenceBanner = sequence(sequenceBanner, optionsBanner);
  }
});

$(function () {
  var galleryExists = document.getElementById("gallery");
  if (galleryExists !== null) {
    var sequenceSlider = document.getElementById("gallery");
    var optionsSlider = {
      keyNavigation: true,
      startingStepAnimatesIn: false,
      autoPlay: false,
      preloader: true,
      reverseWhenNavigatingBackwards: false,
      fadeStepWhenSkipped: false
    };
    var bggSequenceSlider = sequence(sequenceSlider, optionsSlider);
  }
});
